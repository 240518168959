export const themes = {
    sandstorm: {
        b_high: '#736c5e',
        b_inv: '#fc9e21',
        b_low: '#44413c',
        b_med: '#57534a',
        background: '#3b3a36',
        f_high: '#e5ccaf',
        f_inv: '#3b3a36',
        f_low: '#685e4f',
        f_med: '#fc9e21',
    },
    tape: {
        b_high: '#43423e',
        b_inv: '#eb3f48',
        b_low: '#e5e3dc',
        b_med: '#c2c1bb',
        background: '#dad7cd',
        f_high: '#696861',
        f_inv: '#43423e',
        f_low: '#b3b2ac',
        f_med: '#ffffff',
    },
    apollo: {
        b_high: '#000000',
        b_inv: '#e47464',
        b_low: '#322e33',
        b_med: '#201e21',
        background: '#29272b',
        f_high: '#ffffff',
        f_inv: '#000000',
        f_low: '#66606b',
        f_med: '#e47464',
    },
    marble: {
        b_high: '#676164',
        b_inv: '#676164',
        b_low: '#CFD2CD',
        b_med: '#A6A2A2',
        background: '#FBFBF2',
        f_high: '#3a3738',
        f_inv: '#A6A2A2',
        f_low: '#bdb8b8',
        f_med: '#847577',
    },
}
